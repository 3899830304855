export const environment = {
  production: true,
  baseHref: '/merchandising-portal/',
  clientId: '539ef4aa-73cf-42a5-bbc5-55aeffac1156',
  backendApiScope: 'api://d57e2d95-aa72-4c82-911d-dd05c73b0441/access_as_user',
  apiResourceUri:
    'https://crm-api-aus.js2go.johnsands.com/api/',
  reportingUrl: 'https://reporting-ui-aus.js2go.johnsands.com/reporting/',
  find: 'activity/find',
  cloudFrontRequests: '.cloudfront.net/',
  imagesBucket: 'images',
  resizedImagesBucket: 'resized-images',
  dateFormatUS: 'dd/MM/yyyy',
  dateFormatUK: 'dd/MM/yyyy',
  lengthValidators: {
    title: 100,
    description: 1500,
    comment: 100,
    response: 100,
    titleOfSet: 60
  },
  maxFileUploadSize: 3,
  title: 'JS2GO Merchandising Portal',
  landingLogoPath: 'assets/img/js-logo-large.png',
  sidenavLogoPath: 'assets/img/js-logo-min.png',
  shipperName: 'CDU Count',
  emojiRegEx : '^[A-Za-z0-9\\s+!@#$%^&*()-–_+=`~\\\]\[{}|\';:/.,?><]*$'
};
